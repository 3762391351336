/* Overall Page Style */
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2; /* Light gray background */
}

/* Section Style */
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ddd; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  background-color: #fff; /* White background */
}

/* Section Title Style */
.section-title {
  font-size: 1.5rem; /* Adjust font size as needed */
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/* Audio Player Style */
.audio-player {
  width: 100%; /* Adjust width as needed */
  margin-bottom: 10px;
}
