.modal {
    position: fixed; /* Ensures the modal overlays the page content */
    top: 0;
    left: 0;
    /*width: 100%;*/
    /*height: 100%;*/
    background: linear-gradient(rgb(73, 50, 46), rgb(34, 53, 66)) !important; /* Semi-transparent black background */
    display: flex; /* Centers the content vertically and horizontally */
    justify-content: center;
    align-items: center;
    z-index: 100; /* Ensures the modal is displayed above other elements */
}

.modal-content {
    /*background-color: #fff; !* White modal content *!*/
    background: linear-gradient(rgb(255, 251, 227), rgb(75, 149, 181)) !important;
    /*padding: 20px;*/
    border-radius: 5px; /* Rounded corners */
    max-width: 800px; /* Limit modal content width */
    position: relative; /* Allows absolute positioning for the close button */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
}

img {
/* fade out borders of image */
    border-radius: 10px;
}